import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoRootModule } from '../../transloco/transloco-root.module';
import { CompanyOtherInfoProfileComponent } from './company-other-info-profile.component';

@NgModule({
  declarations: [CompanyOtherInfoProfileComponent],
  imports: [CommonModule, TranslocoRootModule],
  exports: [CompanyOtherInfoProfileComponent]
})
export class CompanyOtherInfoProfileModule {}
