export interface ISmartCardCustomData {
  entityId: string;
  smartCardType: ISmartCardType;
  asOfDate?: string;
}

export enum ISmartCardType {
  oneYrPiTPD = '1-Year PiT PD',
  oneYrPiTPDImpliedRating = '1-Year PiT PD Implied Rating',
  oneYrTTCPD = '1-Year TTC PD',
  oneYrTTCPDImpliedRating = '1-Year TTC PD Implied Rating'
}

export interface ICompanyModel {
  id?: string;
  dataType?: string;
  dataSource?: string;
  confidenceIndicator?: string;
  confidenceDescription?: string;
  modelName?: string;
  modelDocumentationLink?: string;
  latestKnownPdDate?: string;
  latestKnownPdValue?: number;
  latestKnownQuantitativePd?: number;
  modelId?: string;
  legalForm: string;
  legalStatus: string;
  legalStatusDate: string;
  impliedRating?: string;
  impliedRatingWithQualitativeOverlay?: string;
  quantitativeImpliedRating?: string;
  pdWithQualitativeOverlay?: number;
}
export interface ICompanyData {
  entity: ICompanyDataEntity;
  peer?: IPeer;
  scores?: IScores;
  ews?: IEws;

  message: string[];
  hasOrbisEntitlements?: boolean;
  riskCategories?: IRiskCategory[];
  kpi?: IKpi;
  pds?: ICompanyPD[];
  triggers?: ICompanyTrigger[];

  historicalTrend?: {
    fso: ICompanyPD[];
    cca: ICompanyPD[];
  };
  pdDrivers?: { [k: string]: number };
  analysisDate?: string;
}

interface IEntity {
  id?: string;
  overlayProcessId?: string;
  parentGroupSupportProcessId?: string;
  parentGroupSupportApplyPd?: boolean;
  name?: string;
  dataType?: IDataType;
  modelId?: string;
  modelName?: string;
  dataSource?: string;
  entityType?: string;
  financialsType?: string;
  confidenceIndicator?: string;
  countryCode?: string;
  stateCode?: string;
  industryCode?: string;
  financialData?: IFinancialData;
  companyDetails?: ICompanyDetails;
  modelDocumentationLinkName?: string;
  modelDocumentationLink?: string;
}
interface ICompanyDataEntity extends IEntity {
  currency?: string;
  financialStatementTessera?: IFinancialStatementsTessera;
  pageNum?: number;
  sizeNum?: number;
  customCompanyId?: string;
}
interface IPeer {
  asOfDate?: string;
  name?: string;
  id?: string;
  percentile?: string;
  medianPd?: number;
  medianPdPreviousYear?: number;
  medianPdYoyChange?: number;
  medianPdYoyBpsChange?: number;
  medianImpliedRating?: string;
  medianImpliedRatingPreviousYear?: string;
  medianImpliedRatingChange?: number;

  groupMetrics?: {
    peerId?: string;
    results?: IPeerGroupResults;
  };
}
export interface IScores {
  latestKnownPdValue?: number;
  latestKnownImpliedRatingLabel?: string;
  latestKnownImpliedRatingRank?: number;
  latestKnownPdDate?: string;
  pdChange?: number;
  pdBpsChange?: number;
  impliedRatingChange?: number;
  pds: ICompanyPD[];
  impliedRatings: ICompanyIR[];
  termStructureType: ITermStructureType;
  fso?: IFso;
  latestKnownQuantitativePd?: number;
  quantitativeImpliedRating?: string;
  pdWithQualitativeOverlay?: number;
  impliedRatingWithQualitativeOverlay?: string;
}
interface IEws {
  label: string;
  rank: number;
  change: string;
  asOfDate: string;
  monthDuration: number;
}
interface IRiskCategory {
  entityID?: string;
  asOfDate?: string;
  irChange?: number;
  trigger?: number;
  pd?: number;
  riskCategory?: string;
  distanceToTrigger?: number;
  message?: string;
  label?: string;
}
interface IKpi {
  pgmPdYoy?: number;
}
enum IDataType {
  BANKRUPT = 'bankrupt',
  PUBLIC = 'Public',
  PRIVATE = 'Private'
}
interface ICompanyPD {
  pd: number;
  asOfDate: string;
}
interface IFinancialData {
  financialAge?: 'R' | 'O' | 'S';
  financialDate?: string;
}
interface ICompanyTrigger {
  asOfDate?: string;
  trigger?: number;
}
interface ICompanyDetails {
  address?: string;
  bvdId?: string;
  customId?: string;
  cusip?: string;
  description?: string;
  domesticParentIdentifier?: string;
  domesticParentName?: string;
  entityContactAddressLine1?: string;
  entityContactAddressLine2?: string;
  entityContactAddressLine3?: string;
  entityContactAddressLine4?: string;
  entityData?: ICompanyEntityData;
  entityWebsite?: string;
  founded?: string;
  globalParentIdentifier?: string;
  globalParentName?: string;
  headquarters?: string;
  headquartersIdentifier?: string;
  headquartersName?: string;
  industry?: string;
  isin?: string;
  contactCity?: string;
  stateCode?: string;
  countryCode?: string;
  isPublic?: boolean;
  isCustom?: boolean;
  legalStatus?: string;
  naicsCode?: string;
  naicsDescription: string;
  nationalId?: INationalIdentifier[];
  ndySector?: string;
  other?: string;
  pid?: string;
  sicCode?: string;
  ticker?: string;
  peerId?: string;
  peerGroupName?: string;
  isBankFlag?: string;
}
interface IFinancialStatementsTessera {
  items?: IFinancialStatement[];
}
interface IFinancialStatement {
  entityIdentifierbvd?: string;
  financialStatementDate?: string;
  statementData?: IStatementData;
  balanceSheet?: IBalanceSheet;
  incomeStatement?: IIncomeStatement;
  auditQuality?: string;
  currency?: string;
  filingType?: string;
  reportingPeriod?: string;
  statementType?: string;
  units?: number;
}
interface IStatementData {
  auditQuality?: string;
  currency?: string;
  isDefaultDatasource?: boolean;
  reportingPeriod?: string;
  statementType?: string;
  filingType?: string;
  units?: number;
  asOfDate?: string;
  model?: string;
}
interface IBalanceSheet {
  [key: string]: string | number;
}
interface IIncomeStatement {
  [key: string]: string | number;
}
interface INationalIdentifier {
  idName: string;
  idValue: string;
}
interface ICompanyEntityData {
  customData: {
    country: string;
    state: string;
    industry: string;
    peerGroupId: string;
  };
  peerGroupName?: string;
  peerId?: string;
}
interface IFso {
  latest?: ICompanyPD;
  previousYear?: ICompanyPD;
}
interface IPeerGroupResults {
  P090?: IChartPointNumberDate[];
  P075?: IChartPointNumberDate[];
  P050?: IChartPointNumberDate[];
  P025?: IChartPointNumberDate[];
}
interface IChartPointNumberDate {
  value: number;
  date: string;
}
interface ICompanyIR {
  impliedRating: string;
  asOfDate: string;
}
interface ITermStructureType {
  cca: ITermStructure;
  fso: ITermStructure;
}
interface ITermStructure {
  forward: ITermStructureForward;
  annualized: ITermStructureAnnualized;
  cumulative: ITermStructureCumulative;
}
interface ITermStructureForward {
  forward1Y: ITermStructureDataWithIR;
  forward2Y: ITermStructureDataWithIR;
  forward3Y: ITermStructureDataWithIR;
  forward4Y: ITermStructureDataWithIR;
  forward5Y: ITermStructureDataWithIR;
  forward6Y: ITermStructureDataWithIR;
  forward7Y: ITermStructureDataWithIR;
  forward8Y: ITermStructureDataWithIR;
  forward9Y: ITermStructureDataWithIR;
  forward10Y: ITermStructureDataWithIR;
}
interface ITermStructureAnnualized {
  annualized1Y: ITermStructureDataWithIR;
  annualized2Y: ITermStructureDataWithIR;
  annualized3Y: ITermStructureDataWithIR;
  annualized4Y: ITermStructureDataWithIR;
  annualized5Y: ITermStructureDataWithIR;
  annualized6Y: ITermStructureDataWithIR;
  annualized7Y: ITermStructureDataWithIR;
  annualized8Y: ITermStructureDataWithIR;
  annualized9Y: ITermStructureDataWithIR;
  annualized10Y: ITermStructureDataWithIR;
}

interface ITermStructureCumulative {
  cumulative1Y: ITermStructureDataWithIR;
  cumulative2Y: ITermStructureDataWithIR;
  cumulative3Y: ITermStructureDataWithIR;
  cumulative4Y: ITermStructureDataWithIR;
  cumulative5Y: ITermStructureDataWithIR;
  cumulative6Y: ITermStructureDataWithIR;
  cumulative7Y: ITermStructureDataWithIR;
  cumulative8Y: ITermStructureDataWithIR;
  cumulative9Y: ITermStructureDataWithIR;
  cumulative10Y: ITermStructureDataWithIR;
}
interface ITermStructureDataWithIR {
  pd: number;
  impliedRating: string;
}

export interface ICompanyProfile extends IEntity {
  processId?: string;
  confidenceDescription?: string;
  peerId: string;
  peerGroupName: string;
  updatedDate?: string;
  processStatus?: string;
  processStatusDate?: string;
  naceCode?: string;
  naceDescription?: string;
}

export interface IMasterData {
  countries: ICountry[];
  usStates: IUSState[];
  ndys: INDYSector[];
  naics: INAICSSector[];
}

export interface ICountry {
  countryName: string;
  iso2Code: string;
  iso3Code: string;
}

export interface IUSState {
  stateCode: string;
  name: string;
}

export interface INDYSector {
  ndyCode: string;
  description: string;
  ndyType: string;
}

export interface INAICSSector {
  naicsCode: string;
  description: string;
  ndyCode: string;
  ndyDescription: string;
}

export interface ICustomEntity {
  NDYsector?: string;
  NDYcode?: string;
  industryClassification?: string;
  primaryIndustryCode?: string;
  primaryIndustrySector?: string;
  entityLegalForm?: string;
  entityName?: string;
  entityId?: string;
  peerId?: string;
  ceCountry?: string;
  ceState?: string;
  customEntityIdentifier?: string;
  description?: string;
  entityType?: string;
  portfolioId?: number;
  created?: {
    by?: string;
    dt?: number;
  };
}

export class DropdownOption {
  value: any;
  label: any;

  constructor(value: any, label: any) {
    this.value = value;
    this.label = label;
  }
}

export interface IEntitlement {
  endDate: string;
  entitlementType: string;
  name: string;
  startDate: string;
}

export interface IEntitySearchResponse {
  entities: IEntitySearch[];
}
export interface IEntitySearch {
  contactAddressLine?: string;
  contactCity?: string;
  contactCountryCode?: string;
  contactPostalCode?: string;
  contactStateProvince?: string;
  customEntityIdentifier?: string;
  entityId?: string;
  id?: string;
  displayId?: string;
  identifierBvd?: string;
  identifierOrbis?: string;
  internationalName?: string;
  primaryIndustryNACE?: string;
  primaryIndustryNAICS?: string;
  primaryIndustryNDY?: string;
  primaryIndustryNDYDescription?: string;
  primaryIndustrySIC?: string;
  size?: string;
  pid?: string;
  ticker?: string;
  type?: string;
  globalParentIdentifier?: string;
  globalParentName?: string;
  domesticParentIdentifier?: string;
  domesticParentName?: string;
  headquartersIdentifier?: string;
  headquartersName?: string;
}

export interface IEntitySearchPayload {
  limit: number;
  offset: number;
  query: string;
}
