<div class="card">
  <div class="card-body">
    <div class="header">
      <h1>{{data.name}}</h1>
      <div class="description slim-scroll" #descriptionContainer [ngClass]="{ 'expanded':  !readMoreDescription}">
        {{data.description}}
        <span *ngIf="readMoreDescription" (click)="clickMoreDescription()"> Read More.</span>
        <span *ngIf="!readMoreDescription" class="read-less" (click)="clickLessDescription()"> Read Less.</span>
      </div>
    </div>
    <ul class="data-list">
      <li *ngIf="data.entityContactAddressLine1">
        <div class="data-key">
          <span class="material-icons-outlined icon"> location_on </span>
          <strong>{{"PROFILE.ADDRESS"| transloco}}</strong>
        </div>
        <div class="data-value">
          <span *ngIf="data.entityContactAddressLine1"> {{data.entityContactAddressLine1}} <br /></span>
          <span *ngIf="data.entityContactAddressLine2"> {{data.entityContactAddressLine2}} <br /></span>
          <span *ngIf="data.entityContactAddressLine3"> {{data.entityContactAddressLine3}} <br /></span>
          <span *ngIf="data.entityContactAddressLine4"> {{data.entityContactAddressLine4}} <br /></span>
          <span *ngIf="data.entityCity"> {{data.entityCity}} <br /></span>
          <span *ngIf="data.entityState"> {{data.entityState}},</span>
          <span *ngIf="data.entityCountry"> {{data.entityCountry}}</span>
        </div>
      </li>
      <li *ngIf="data.entityWebsite">
        <div class="data-key">
          <span class="material-icons-outlined icon"> travel_explore </span>
          <strong>{{"PROFILE.WEBSITE"| transloco}}</strong>
        </div>
        <a class="data-value blue" href="https://{{data.entityWebsite}}" target="_blank">{{data.entityWebsite}}</a>
      </li>
      <li *ngIf="data.founded">
        <div class="data-key">
          <span class="material-icons-outlined icon"> date_range </span>
          <strong>{{"PROFILE.YEAR_FOUNDED"| transloco}}</strong>
        </div>
        <span class="data-value">{{data.founded}}</span>
      </li>
      <li *ngIf="data.legalStatus">
        <div class="data-key">
          <span class="material-icons-outlined icon"> flag </span>
          <strong>{{"PROFILE.LEGAL_STATUS"| transloco}}</strong>
        </div>
        <span class="data-value">{{data.legalStatus}}</span>
      </li>
      <li *ngIf="data.ticker">
        <div class="data-key">
          <span class="material-icons-outlined icon"> trending_up </span>
          <strong>{{"PROFILE.TICKER"| transloco}}</strong>
        </div>
        <span class="data-value">{{data.ticker}}</span>
      </li>
      <li *ngIf="data.bvdId && !data.isCustom">
        <div class="data-key">
          <span class="material-icons-outlined icon"> article </span>
          <strong>{{"PROFILE.BVD_ID"| transloco}}</strong>
        </div>
        <span class="data-value">{{data.bvdId}}</span>
      </li>
      <li *ngIf="data.customId && data.isCustom">
        <div class="data-key">
          <span class="material-icons-outlined icon"> article </span>
          <strong>{{"PROFILE.BVD_ID"| transloco}}</strong>
        </div>
        <span class="data-value">{{data.customId}}</span>
      </li>
    </ul>
    <div class="map-container" *ngIf="getAddress()">
      <edfx-edfx-map [address]="getAddress()"></edfx-edfx-map>
    </div>
  </div>
</div>
