// This file can be replaced during build by using the `fileReplenvironmentname: string;  name: Raacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { IEnvironment } from '../../../../../interfaces/environment.interface';
export const environment: IEnvironment = {
  production: false,
  environmentName: 'DEV Deployment',
  name: 'DEV',
  endPointConfig: {
    apiUrl: 'https://ci-api.edfx.moodysanalytics.net/tesseraui/v1',
    edfxUrl: 'https://ci.edfx.moodysanalytics.net',
  },
  features: {
    maps: {
      url: 'https://www.google.com/maps/embed/v1/place?key=',
      key: 'AIzaSyDk5N5UhGAbCrSll7xWvFmJrWJqZRCNqCw',
      searchUrl: 'https://www.google.com/maps/search/'
    }
  }
};
