import { Component, Input, OnInit } from '@angular/core';
import { ICompanyCustomData } from '../interfaces/company-data.interface';
import * as translocoData from '../../assets/i18n/en.companyProfile.json';

@Component({
  selector: 'edfx-company-editable-profile',
  templateUrl: './company-editable-profile.component.html',
  styleUrls: ['./company-editable-profile.component.scss']
})
export class CompanyEditableProfileComponent implements OnInit {
  tooltipText = 'The Country, State or Industry has been manually modified';
  @Input() data: ICompanyCustomData;
  localizationData: any;
  constructor() {}

  ngOnInit(): void {
    this.localizationData = JSON.parse(JSON.stringify(translocoData));
  }
}
