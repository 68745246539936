<div class="card">
  <div class="card-body">
    <ul class="data-list">
      <li *ngIf="data.country">
        <div class="data-key">
          <strong>{{localizationData.PROFILE.COUNTRY}}</strong>
        </div>
        <span class="data-value">{{data.country}}</span>
      </li>
      <li *ngIf="data.state && data.countryCode === 'USA'">
        <div class="data-key">
          <strong>{{localizationData.PROFILE.STATE}}</strong>
        </div>
        <span class="data-value">{{data.state}}</span>
      </li>
      <li *ngIf="data.ndySector">
        <div class="data-key">
          <strong>{{localizationData.PROFILE.NDY}}</strong>
        </div>
        <span class="data-value">{{data.ndySector}} - {{data.industry | lowercase}}</span>
      </li>
      <li *ngIf="data.naicsCode">
        <div class="data-key">
          <strong>{{localizationData.PROFILE.NAICS}}</strong>
        </div>
        <span class="data-value">{{data.naicsCode}}{{data.naicsDescription ? ' - '+data.naicsDescription : '' | lowercase}}</span>
      </li>
      <li *ngIf="data.naceCode">
        <div class="data-key">
          <strong>{{localizationData.PROFILE.NACE}}</strong>
        </div>
        <span class="data-value">{{data.naceCode}} - {{data.naceDescription | lowercase}}</span>
      </li>
      <li *ngIf="data.sicCode">
        <div class="data-key">
          <strong>{{localizationData.PROFILE.SIC}}</strong>
        </div>
        <span class="data-value">{{data.sicCode}}</span>
      </li>
    </ul>
    <div class="custom-data-info" *ngIf="data.hasCustomData">
      <edfx-info-icon
        [tooltip]="tooltipText"
        [tooltipPlacement]="'bottom'"
        tooltipMaxWidth="180px"
        style="position: relative; top: -2px"
      ></edfx-info-icon>
      <i>{{localizationData.PROFILE.CUSTOM_DATA}}</i>
    </div>
  </div>
</div>
