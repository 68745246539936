import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EdfxInfoIconComponent } from './edfx-info-icon.component';
import { EdfxTooltipModule } from '../edfx-tooltip/edfx-tooltip.module';

@NgModule({
  declarations: [EdfxInfoIconComponent],
  exports: [EdfxInfoIconComponent],
  imports: [CommonModule, EdfxTooltipModule],
})
export class EdfxInfoIconModule {}
