import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoRootModule } from '../../transloco/transloco-root.module';
import { EdfxInfoIconModule } from '../edfx-info-icon/edfx-info-icon.module';
import { CompanyEditableProfileComponent } from './company-editable-profile.component';

@NgModule({
  declarations: [CompanyEditableProfileComponent],
  exports: [CompanyEditableProfileComponent],
  imports: [CommonModule, EdfxInfoIconModule, EdfxInfoIconModule, TranslocoRootModule]
})
export class CompanyEditableProfileModule {}
