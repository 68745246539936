import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HTTP_CORRELATION_ID, HTTP_HEADERS_AUTHORIZATION, HTTP_SESSION_ID } from 'interfaces/constants';
import { Observable, from } from 'rxjs';
import { SessionService } from 'services/session.service';
import { ICompanyModel, ICompanyProfile, IEntitlement, IEntitySearchResponse, IMasterData } from './types';
import { Utils } from './utils/utils';

interface IHttpGetOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  observe?: 'body';
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

interface IHttpGetJsonOptions {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  observe?: 'body';
  reportProgress?: boolean;
  responseType?: 'json' | 'text';
  withCredentials?: boolean;
}

interface IHttpGetArrayBufferOptions {
  observe?: 'body';
  responseType: 'arraybuffer' | 'blob';
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  withCredentials?: boolean;
}

interface IHttpPostOptions {
  headers?: HttpHeaders | { [p: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [p: string]: string | string[] };
  reportProgress?: boolean;
  transformRequest?: any;
  responseType?: 'json';
  withCredentials?: boolean;
}

interface IHttpPatchOptions {
  headers?: HttpHeaders | { [p: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [p: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

interface IHttpPutOptions {
  headers?: HttpHeaders | { [p: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [p: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

interface IHttpDeleteOptions {
  headers?: HttpHeaders | { [p: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [p: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected readonly baseUrl!: string;
  protected readonly sessionId!: string;
  token!: string;

  constructor(protected http: HttpClient, protected sessionService: SessionService) {
    this.baseUrl = '';
    this.sessionId = sessionService.sessionId ?? '';
  }

  public fetchJson<T>(path: string): Observable<T> {
    return from(fetch(path).then(res => res.json()));
  }

  public get(path: string, options: IHttpGetArrayBufferOptions): Observable<ArrayBuffer>;
  public get<T>(path: string, options?: IHttpGetJsonOptions): Observable<T>;
  public get(path: string, options: IHttpGetOptions = {}): Observable<any> {
    return this.http.get(this.baseUrl + path, this.getOptions(options) as any);
  }
  public post<T>(path: string, body: any | null, options?: IHttpPostOptions): Observable<T> {
    return this.http.post<T>(this.baseUrl + path, body, this.getOptions(options) as IHttpPostOptions);
  }

  private getOptions(options: any): any {
    const headers: { [p: string]: string } = {};
    headers[HTTP_SESSION_ID] = this.sessionId;
    headers[HTTP_CORRELATION_ID] = Utils.generateUUID();
    headers[HTTP_HEADERS_AUTHORIZATION] = 'Bearer ' + this.token;

    if (!options) {
      options = {};
    }
    options = { ...options, headers: { ...options.headers, ...headers } };
    return options;
  }

  setToken(token: string) {
    this.token = token;
  }

  getCompanyProfile(entityId: string): Observable<ICompanyProfile> {
    return this.get<ICompanyProfile>(`${environment.endPointConfig.apiUrl}/entity/${entityId}/profile`);
  }

  getMasterData(): Observable<IMasterData> {
    return this.get<IMasterData>(`${environment.endPointConfig.apiUrl}/masterData`);
  }

  public getModel(entityId: string, analysisDate: string): Observable<ICompanyModel> {
    return this.get<ICompanyModel>(`${environment.endPointConfig.apiUrl}/entity/${entityId}/model?analysisDate=${analysisDate}`);
  }

  public getEntitlements(payload: string[]): Observable<IEntitlement[]> {
    return this.post<IEntitlement[]>(`${environment.endPointConfig.apiUrl}/entitlements`, payload);
  }

  public getEntityId(query: string, isCustom: boolean): Observable<IEntitySearchResponse> {
    const payload = isCustom ? { queries: [{ customEntityIdentifier: query }] } : { queries: [{ entityId: query }] };
    return this.post(`${environment.endPointConfig.entityUrl}/mapping`, payload);
  }
}
