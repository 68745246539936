<div class="card">
  <div class="card-body">
    <h1 class="w-100">{{"PROFILE.OTHER_IDENTIFIERS"| transloco}}</h1>
    <ul class="data-list">
      <li *ngIf="data.moodysPid">
        <div class="data-key">
          <strong>{{"PROFILE.PID"| transloco}}</strong>
        </div>
        <span class="data-value">{{data.moodysPid}}</span>
      </li>
      <li *ngIf="data.cusip">
        <div class="data-key">
          <strong>{{"PROFILE.CUSIP"| transloco}}</strong>
        </div>
        <span class="data-value">{{data.cusip}}</span>
      </li>
      <li *ngIf="data.isin">
        <div class="data-key">
          <strong>{{"PROFILE.ISIN"| transloco}}</strong>
        </div>
        <span class="data-value">{{data.isin}}</span>
      </li>
      <li *ngIf="data.domesticOwner">
        <div class="data-key">
          <strong>{{"PROFILE.DOMESTIC_OWNER"| transloco}}</strong>
        </div>
        <a [href]="[redirectToParent('domestic')]" target="_blank" class="data-value blue">{{data.domesticOwner}}</a>
      </li>
      <li *ngIf="data.globalOwner">
        <div class="data-key">
          <strong>{{"PROFILE.GLOBAL_OWNER"| transloco}}</strong>
        </div>
        <a [href]="[redirectToParent('global')]" target="_blank" class="data-value blue">{{data.globalOwner}}</a>
      </li>
    </ul>
  </div>
</div>
