import { IEnvironment } from 'interfaces/environment.interface';

export const environment: IEnvironment = {
  production: true,
  environmentName: 'Production',
  name: 'PROD',
  endPointConfig: {
    apiUrl: 'https://api.edfx.moodysanalytics.com/tesseraui/v1',
    mmsApiUrl: 'https://api.edfx.moodysanalytics.com/mms/v1',
    entityUrl: 'https://api.edfx.moodysanalytics.com/entity/v1',
    edfxUrl: 'https://edfx.moodysanalytics.com'
  },
  features: {
    maps: {
      url: 'https://www.google.com/maps/embed/v1/place?key=',
      key: 'AIzaSyDk5N5UhGAbCrSll7xWvFmJrWJqZRCNqCw',
      searchUrl: 'https://www.google.com/maps/search/'
    }
  }
};
