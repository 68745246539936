import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ICompanyOtherIdentifiers } from '../interfaces/company-data.interface';
import { environment } from 'environments/environment';
import * as translocoData from '../../assets/i18n/en.companyProfile.json';

@Component({
  selector: 'edfx-company-other-info-profile',
  templateUrl: './company-other-info-profile.component.html',
  styleUrls: ['./company-other-info-profile.component.scss']
})
export class CompanyOtherInfoProfileComponent implements OnInit, AfterViewInit {
  @Input() data: ICompanyOtherIdentifiers;
  localizationData: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.localizationData = JSON.parse(JSON.stringify(translocoData));
  }

  ngAfterViewInit(): void { }

  public redirectToParent(parentType: string): string {
    let entityId: string;

    switch (parentType) {
      case 'global':
        entityId = this.data.globalOwnerId;
        break;
      case 'domestic':
        entityId = this.data.domesticOwnerId;
        break;
    }

    return `${environment.endPointConfig.edfxUrl}/company/${entityId}/summary`;
  }
}
