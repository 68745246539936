import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { ICompanyMainData } from '../interfaces/company-data.interface';
import * as translocoData from '../../assets/i18n/en.companyProfile.json';

@Component({
  selector: 'edfx-company-main-profile',
  templateUrl: './company-main-profile.component.html',
  styleUrls: ['./company-main-profile.component.scss']
})
export class CompanyMainProfileComponent implements OnInit, AfterViewInit {
  @Input()
  data!: ICompanyMainData;
  @ViewChild('descriptionContainer')
  descriptionContainer!: ElementRef;
  readMoreDescription = true;
  @Output() openExternalSite = new EventEmitter<string>();
  localizationData: any;

  ngOnInit(): void {
    this.localizationData = JSON.parse(JSON.stringify(translocoData));
  }

  ngAfterViewInit(): void {
    this.readMoreDescription = this.descriptionContainer.nativeElement.scrollHeight > 60 ? true : false;
  }

  clickMoreDescription(): void {
    this.readMoreDescription = false;
  }

  clickLessDescription(): void {
    this.readMoreDescription = true;
    this.descriptionContainer.nativeElement.scrollTop = 0;
  }

  getDisplayState() {
    let displayState = '';
    if (this.data.entityState) {
      displayState = this.data.entityState;
    } else if (this.data.entityStateCode) {
      displayState = this.data.entityStateCode;
    }
    return displayState;
  }
  getDisplayCountry() {
    let displayCountry = '';
    if (this.data.entityCountry) {
      displayCountry = this.data.entityCountry;
    } else if (this.data.entityCountryCode) {
      displayCountry = this.data.entityCountryCode;
    }
    return displayCountry;
  }

  getAddress(): string {
    const address = [];
    if (this.data.entityContactAddressLine1) {
      address.push(this.data.entityContactAddressLine1);
    }
    if (this.data.entityContactAddressLine2) {
      address.push(this.data.entityContactAddressLine2);
    }
    if (this.data.entityContactAddressLine3) {
      address.push(this.data.entityContactAddressLine3);
    }
    if (this.data.entityContactAddressLine4) {
      address.push(this.data.entityContactAddressLine4);
    }
    if (this.data.entityCity) {
      address.push(this.data.entityCity);
    }
    const displayState = this.getDisplayState();
    const displayCountry = this.getDisplayCountry();
    if (displayState) {
      address.push(displayState);
    }
    if (displayCountry) {
      address.push(displayCountry);
    }
    if (this.data.entityCountry) {
      address.push(this.data.entityCountry);
    } else if (this.data.entityCountryCode) {
      address.push(this.data.entityCountryCode);
    }
    return address.toString();
  }

  getMapsSearchUrl(): void {
    this.openExternalSite.emit(encodeURI(environment.features.maps.searchUrl + this.getAddress()));
  }
}
