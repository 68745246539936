<link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap" rel="stylesheet" />
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
<link href="https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined" rel="stylesheet" />

<ng-container
  *ngIf="!requestInProgress && !tokenInvalid && hasEntitlement && !invalidEntityId && !loading; else tokenInvalidMessage">
  <div class="card-container" *ngIf="loading === false" [ngClass]="{'no-other-info': !otherInfoHasData}">
    <edfx-company-main-profile [data]="mainInfoData"></edfx-company-main-profile>
    <edfx-company-editable-profile [data]="editableInfoData"></edfx-company-editable-profile>
    <edfx-company-other-info-profile *ngIf="otherInfoHasData"
      [data]="otherIdentifiersData"></edfx-company-other-info-profile>
  </div>
</ng-container>
<div fxLayout="row" fxLayoutAlign="space-around center" style="background-color: none;">
  <div class="spinner" *ngIf="loading && hasEntitlement"></div>
</div>
<ng-template #tokenInvalidMessage>
  <p class="token-invalid-message" *ngIf="tokenInvalid && !requestInProgress && !loading">
    Token provided to access Company Profile snippets is expired or invalid.
  </p>
</ng-template>

<ng-container *ngIf="!hasEntitlement && !requestInProgress && !loading">
  <p class="token-invalid-message">User does not have EDF-X access. Please contact your Moody's representative.</p>
</ng-container>

<ng-container *ngIf="invalidEntityId && !loading">
  <p class="token-invalid-message">Entity id {{entityId}} does not exists.</p>
</ng-container>