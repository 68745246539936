import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { EdfxTooltipDirective } from './edfx-tooltip.directive';

@NgModule({
  declarations: [EdfxTooltipDirective],
  exports: [EdfxTooltipDirective],
  imports: [CommonModule, MdbTooltipModule]
})
export class EdfxTooltipModule {}
