import { formatDate } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Entitlements } from 'interfaces/constants';
import moment from 'moment';
import { Observable, Subject, catchError, combineLatest, of, takeUntil } from 'rxjs';
import { ApiService } from './api.service';
import { ICompanyCustomData, ICompanyMainData, ICompanyOtherIdentifiers } from './interfaces/company-data.interface';
import { DropdownOption, ICompanyModel, ICompanyProfile, ICustomEntity, IEntitlement, IEntitySearchResponse, IMasterData } from './types';
import { Utils } from './utils/utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class AppComponent implements OnInit, OnChanges, OnDestroy {
  title = 'company-profile';
  loading = true;
  @HostBinding('class.no-background') get noBackground() {
    return this.loading;
  }

  mainInfoData!: ICompanyMainData;
  editableInfoData: ICompanyCustomData;
  otherIdentifiersData: ICompanyOtherIdentifiers;
  today = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');

  customCompanyData!: ICustomEntity;
  companyProfile!: ICompanyProfile;
  companyModel: ICompanyModel;
  profileIsEditable$: Observable<boolean> | undefined;
  countryOptions: DropdownOption[] = [];
  stateOptions: DropdownOption[] = [];
  sectorOptions: DropdownOption[] = [];
  sectorDropdownOptions: DropdownOption[] = [];
  defaultCountryCode!: string;
  defaultStateCode!: string;
  defaultSectorCode!: string;
  public otherInfoHasData = true;
  tokenInvalid = false;
  requestInProgress = false;
  COUNTRY_CODE_USA = 'USA';
  private destroy$: Subject<boolean> = new Subject();
  hasEntitlement = false;
  invalidEntityId = false;

  @Input() entityId!: string;
  @Input() token!: string;

  constructor(private apiService: ApiService) {
    // Only for dev testing, comment out for building the web element!
    // this.entityId = 'US912197729';
    // eslint-disable-next-line max-len
    // this.token = '';
  }

  ngOnInit(): void {
    this.getCompanyInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Below logic avoids duplicate calls to get company profile
    //by using shouldGetCompanyInfo local variable
    //If we directly called this.getCompanyInfo(); from entityId and token change
    //which happens when you pass data into host app form first time
    //we call the API's twice.

    let shouldGetCompanyInfo = false;

    if (changes['entityId'] && !Utils.isNullOrUndefined(changes['entityId'].currentValue)) {
      this.entityId = changes['entityId'].currentValue;
      shouldGetCompanyInfo = true;
    }

    if (changes['token'] && !Utils.isNullOrUndefined(changes['token'].currentValue)) {
      this.token = changes['token'].currentValue;
      shouldGetCompanyInfo = true;
    }

    if (shouldGetCompanyInfo) {
      this.getCompanyInfo();
    }
  }

  private getCompanyInfo() {
    this.loading = true;
    const entitlement_list = [
      Entitlements.MIR_MEDIAN_CREDIT_SPREAD,
      Entitlements.ORBIS_ALL_COMPANIES,
      Entitlements.PRIVATE_ENTITLEMENT,
      Entitlements.PUBLIC_ENTITLEMENT,
      Entitlements.PUBLIC_PLUS_ENTITLEMENT
    ];
    if (!this.entityId) {
      return;
    }

    if (!Utils.isNullOrUndefined(this.token)) {
      this.apiService.setToken(this.token);
    }

    this.tokenInvalid = false;
    this.requestInProgress = true;

    this.apiService
      .getEntitlements(entitlement_list)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
          }
          this.requestInProgress = false;
          this.hasEntitlement = true;
          this.loading = false;
          return of();
        })
      )
      .subscribe(entitlements => this.onEntitlementsReceived(entitlements));
  }

  onEntitlementsReceived(entitlements: IEntitlement[]) {
    this.hasEntitlement = entitlements?.some(
      entitlement => entitlement.name === Entitlements.PRIVATE_ENTITLEMENT || entitlement.name === Entitlements.PUBLIC_ENTITLEMENT
    );
    if (!this.hasEntitlement) {
      this.requestInProgress = false;
      this.loading = false;
      return;
    }

    this.getEntityId();
  }

  getEntityId(): void {
    const currentEntityId = this.entityId;
    this.apiService
      .getEntityId(currentEntityId, false)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
          }
          this.requestInProgress = false;
          this.loading = false;
          return of();
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.requestInProgress = false;
          this.invalidEntityId = true;
          this.loading = false;
          return;
        }
        if (entityIdResult.entities.length === 0) {
          this.getCustomEntityId(currentEntityId);
          return;
        }

        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.onEntityIdReceived();
      });
  }

  getCustomEntityId(currentEntityId: string) {
    this.apiService
      .getEntityId(currentEntityId, true)
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
          }
          this.requestInProgress = false;
          this.loading = false;
          return of();
        })
      )
      .subscribe((entityIdResult: IEntitySearchResponse) => {
        if (Utils.isNullOrUndefined(entityIdResult)) {
          this.requestInProgress = false;
          this.loading = false;
          this.invalidEntityId = true;
          return;
        }

        if (entityIdResult?.entities?.length === 0) {
          this.requestInProgress = false;
          this.loading = false;
          this.invalidEntityId = true;
          return;
        }

        this.entityId = entityIdResult?.entities?.length > 0 ? entityIdResult.entities[0]?.entityId : this.entityId;
        this.onEntityIdReceived();
      });
  }

  onEntityIdReceived(): void {
    combineLatest([
      this.apiService.getCompanyProfile(this.entityId),
      this.apiService.getMasterData(),
      this.apiService.getModel(this.entityId, moment().format('YYYY-MM-DD'))
    ])
      .pipe(
        takeUntil(this.destroy$),
        catchError(error => {
          if (error.status === 401 || error.status === 0) {
            this.tokenInvalid = true;
          }
          this.requestInProgress = false;
          this.loading = false;
          return of();
        })
      )
      .subscribe(([profile, masterData, model]) => {
        this.onEntityInfoRecieved(profile, masterData, model);
      });
  }

  onEntityInfoRecieved(profile: ICompanyProfile, masterData: IMasterData, model: ICompanyModel) {
    if (!profile) {
      return;
    }
    this.requestInProgress = false;
    this.companyProfile = profile;
    this.companyModel = model;
    this.countryOptions = masterData.countries.map(country => new DropdownOption(country.iso3Code, country.countryName));
    this.stateOptions = masterData.usStates.map(state => new DropdownOption(!state.stateCode ? '--' : state.stateCode, state.name));
    this.sectorDropdownOptions = masterData.ndys.map(ndy => new DropdownOption(ndy.ndyCode, `${ndy.ndyCode} - ${ndy.description}`));
    this.sectorOptions = masterData.ndys.map(ndy => new DropdownOption(ndy.ndyCode, ndy.description));
    this.feedChildComponents();
    this.loading = false;
  }

  getCompanyStateFromCode(stateCode?: string) {
    return this.stateOptions.find(opt => opt.value === stateCode)?.label;
  }

  getCompanyCountryFromCode(countryCode?: string) {
    return this.countryOptions.find(opt => opt.value === countryCode)?.label;
  }

  getCompanyName() {
    return this.companyProfile?.name || 'N/A';
  }

  getCompanyDescription() {
    return this.companyProfile?.companyDetails?.description || '';
  }

  feedChildComponents(): void {
    this.mainInfoData = {
      address: this.companyProfile?.companyDetails?.address,
      state: this.getCompanyStateFromCode(this.defaultStateCode),
      country: this.getCompanyCountryFromCode(this.defaultCountryCode),
      bussinessName: this.companyProfile?.companyDetails?.domesticParentName,
      bvdId: this.companyProfile?.companyDetails?.bvdId,
      founded: this.companyProfile?.companyDetails?.founded,
      legalStatus: this.companyModel?.legalStatus,
      ticker: this.companyProfile?.companyDetails?.ticker,
      website: '',
      name: this.getCompanyName(),
      description: this.getCompanyDescription(),
      entityWebsite: this.companyProfile?.companyDetails?.entityWebsite,
      entityContactAddressLine1: this.companyProfile.companyDetails?.entityContactAddressLine1,
      entityContactAddressLine2: this.companyProfile.companyDetails?.entityContactAddressLine2,
      entityContactAddressLine3: this.companyProfile.companyDetails?.entityContactAddressLine3,
      entityContactAddressLine4: this.companyProfile.companyDetails?.entityContactAddressLine4,
      stateCode: this.companyProfile.companyDetails?.stateCode,
      entityStateCode: this.companyProfile.companyDetails?.stateCode,
      entityState: this.getCompanyStateFromCode(this.companyProfile.companyDetails?.stateCode),
      entityCity: this.companyProfile.companyDetails?.contactCity,
      countryCode: this.companyProfile.companyDetails?.countryCode,
      entityCountryCode: this.companyProfile.companyDetails?.countryCode,
      entityCountry: this.getCompanyCountryFromCode(this.companyProfile.companyDetails?.countryCode),
      isCustom: this.companyProfile.companyDetails?.isCustom,
      customId: this.companyProfile.companyDetails?.customId
    };

    this.editableInfoData = {
      country: this.getCompanyCountryFromCode(this.getCompanyCountryCode()),
      countryCode: this.getCompanyCountryCode(),
      industry: this.getCompanyNDYSector(),
      naicsCode: this.companyProfile?.companyDetails.naicsCode,
      naicsDescription: this.companyProfile?.companyDetails.naicsDescription,
      naceCode: this.companyProfile?.naceCode,
      naceDescription: this.companyProfile?.naceDescription,
      ndySector: this.getCompanyNDYSectorCode(),
      sicCode: this.companyProfile?.companyDetails.sicCode,
      hasCustomData: this.hasCustomData(),
      state: this.getCompanyStateFromCode(this.getCompanyStateCode())
    };

    this.otherIdentifiersData = {
      moodysPid: this.companyProfile?.companyDetails.pid,
      cusip: this.companyProfile?.companyDetails.cusip,
      isin: this.companyProfile?.companyDetails.isin,
      domesticOwner: this.companyProfile?.companyDetails.domesticParentName,
      domesticOwnerId: this.companyProfile?.companyDetails.domesticParentIdentifier,
      globalOwner: this.companyProfile?.companyDetails.globalParentName,
      globalOwnerId: this.companyProfile?.companyDetails.globalParentIdentifier
    };

    this.otherInfoHasData = !!Object.values(this.otherIdentifiersData).reduce((acc, curr) => (!!curr ? acc + 1 : acc), 0);
  }

  getCompanyCountryCode() {
    let code = this.hasCustomData()
      ? this.companyProfile?.companyDetails?.entityData?.customData?.country
      : this.companyProfile?.companyDetails?.countryCode;
    if (!code) {
      code = this.defaultCountryCode;
    }
    return code;
  }

  getCompanyNDYSectorCode() {
    let code = this.hasCustomData()
      ? this.companyProfile?.companyDetails?.entityData?.customData?.industry
      : this.companyProfile?.companyDetails?.ndySector;
    if (!code) {
      code = this.defaultSectorCode;
    }
    return code;
  }

  getCompanyNDYSector(): string {
    return this.sectorOptions.find(opt => opt.value === this.getCompanyNDYSectorCode())?.label || 'N/A';
  }

  hasCustomData(): boolean {
    const customData = this.companyProfile.companyDetails.entityData.customData;
    if (!customData) {
      return false;
    }

    return (
      !this.companyProfile.companyDetails.isCustom &&
      ((customData.country && customData.country !== this.defaultCountryCode) ||
        (customData.industry && customData.industry !== this.defaultSectorCode) ||
        (customData.state && customData.state !== this.defaultStateCode))
    );
  }

  getCompanyStateCode() {
    let code = null;
    if (this.getCompanyCountryCode() === this.COUNTRY_CODE_USA) {
      code = this.hasCustomData()
        ? this.companyProfile?.companyDetails?.entityData?.customData?.state
        : this.companyProfile?.companyDetails?.stateCode;
      if (!code) {
        code = this.defaultStateCode;
      }
    }

    return code;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
