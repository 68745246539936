import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'edfx-edfx-map',
  templateUrl: './edfx-map.component.html',
  styleUrls: ['./edfx-map.component.scss']
})
export class EdfxMapComponent implements AfterViewInit {
  @Input()
  latitude!: number;
  @Input()
  longitude!: number;
  @Input()
  address!: string;
  @ViewChild('mapIframe')
  mapIframe!: ElementRef<HTMLIFrameElement>;

  constructor() {}

  ngAfterViewInit(): void {
    this.intializeMap();
  }

  intializeMap(): void {
    const map = this.mapIframe.nativeElement;
    const location = this.latitude && this.longitude ? `${this.latitude}, ${this.longitude}` : this.address;

    map.src = `${environment.features.maps.url}${environment.features.maps.key}&q=${location}`;
  }
}
