<span
  class="edfx-info-label"
  *ngIf="label"
  [edfxTooltip]="tooltip"
  [edfxTooltipTitle]="tooltipTitle"
  [style.color]="color"
  [edfxTooltipMaxWidth]="tooltipMaxWidth"
  >{{label}}</span
>

<span
  class="{{'material-icons' + (outlined?'-outlined': '')}} edfx-info-icon"
  [edfxTooltip]="tooltip"
  [edfxTooltipTitle]="tooltipTitle"
  [edfxTooltipPlacement]="tooltipPlacement"
  [edfxTooltipTrigger]="tooltipTrigger"
  [style.color]="color"
  [edfxTooltipMaxWidth]="tooltipMaxWidth"
  >{{iconName}}
</span>
