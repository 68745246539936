import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { createCustomElement } from '@angular/elements';
import { TranslocoRootModule } from '../transloco/transloco-root.module';
import { AppComponent } from './app.component';
import { CompanyEditableProfileModule } from './company-editable-profile/company-editable-profile.module';
import { CompanyMainProfileModule } from './company-main-profile/company-main-profile.module';
import { CompanyOtherInfoProfileModule } from './company-other-info-profile/company-other-info-profile.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [AppComponent],
  providers: [],
  bootstrap: [],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    CompanyMainProfileModule,
    CompanyEditableProfileModule,
    CompanyOtherInfoProfileModule,
    TranslocoRootModule,
    FlexLayoutModule
  ]
})
export class AppModule {
  constructor(private injector: Injector) {
    const companyProfileElement = createCustomElement(AppComponent, {
      injector
    });
    customElements.define('edfx-company-profile', companyProfileElement);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngDoBootstrap() { }
}
