import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoRootModule } from '../../transloco/transloco-root.module';
import { EdfxMapModule } from '../edfx-map/edfx-map.module';
import { CompanyMainProfileComponent } from './company-main-profile.component';

@NgModule({
  declarations: [CompanyMainProfileComponent],
  imports: [CommonModule, EdfxMapModule, TranslocoRootModule],
  exports: [CompanyMainProfileComponent]
})
export class CompanyMainProfileModule {}
