export const COUNTRY_CODE_USA = 'USA';
export const DEFAULT_TEXT_NA = 'N/A';

export const FREQUENCY_ANNUAL = 'annual';
export const FREQUENCY_MONTHLY = 'monthly';
export const FREQUENCY_DAILY = 'daily';

export const NAV_ITEM_PORTFOLIOS = 'portfolios';
export const NAV_ITEM_TOOLS = 'tools';
export const NAV_ITEM_ALERTS = 'alerts';
export const NAV_ITEM_MODEL_VERSIONS = 'model-version';
export const NAV_ITEM_BOND_SCREENER = 'bond-screener';

export const ASSET_VOLATILITY_METRIC_NAME = 'totalassetvolatility';
export const MARKET_LEVERAGE_METRIC_NAME = 'marketleverage';
export const ONE_YEAR_PD_PEER_PERCENTILE_METRIC_NAME =
  'annualizedcumulativepd1y';
export const MODEL_CREDIT_EDGE = 'CreditEdge';
export const MODEL_PARENT = 'Parent';

export const INACTIVE = 'inactive';

export const EDFX_TENANT_ADMIN_ROLE = 'EDFXTenantAdmin';

export const MOMENT_FORMATS = {
  payload: 'YYYY-MM-DD',
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
    dayShortMonthYearLabel: 'MMM D, YYYY',
    dayMonthYearLabel: 'MMMM D, YYYY',
    fulldayMonthYearLabel: 'MMMM DD, YYYY',
    fulldayMonthYearWithTimeLabel: 'MMMM DD, YYYY [at] hh:mm A',
  },
};

export const FIELD_TYPES = {
  PERCENTILE: 'percentile',
};

export const ALLOWED_KEYS = {
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  BACKSPACE: 'Backspace',
};

export const INSOLVENT_BANKRUPT = ['act_imp', 'bkrpt'];
export const BANKRUPT_INACTIVE_LIQUIDATED = ['bkrpt', 'not_act', 'not_act_liq'];

export const enum EDFXScenarios {
  BASELINE = 'Baseline',
  S1 = 'S1',
  S2 = 'S2',
  S3 = 'S3',
  S4 = 'S4',
}

export const enum EWSRankings {
  INACTIVE_RANK = -1,
  LOW_RANK = 0,
  MEDIUM_RANK = 1,
  HIGH_RANK = 2,
  SEVERE_RANK = 3,
}

export const DEFAULT_SCENARIOS_PROJECTION_YEARS = 3;

export const LGD_BLANKET_LIEN_COLLATERAL = 'No';
export const LGD_SECURED_UNSECURED_COLLATERAL = 'Secured';

export const HTTP_CORRELATION_ID = 'x-correlation-id';
export const HTTP_SESSION_ID = 'x-session-id';
export const HTTP_HEADERS_AUTHORIZATION = 'Authorization';
export const METRIC_CARD_CONFIG_ITEMS = [
  {
    id: 'deterioration-probability',
    label: 'Deterioration Probability',
    isDefault: false,
    isDisabled: false,
  },

  {
    id: '1-yr-pit-pd-yoy-aod',
    label: '1-Year PiT PD | YoY Change',
    isDefault: true,
    isDisabled: false,
  },
  {
    id: '1-yr-pit-pd-ir-yoy',
    label: '1-Year PiT PD Implied Rating | YoY Change',
    isDefault: true,
    isDisabled: false,
  },
  {
    id: '1-year-pit-pd-pgm-yoy-aod',
    label: '1-Year PiT PD Peer Group Median | YoY Change',
    isDefault: true,
    isDisabled: false,
  },

  {
    id: 'peer-group-median-ir-yoy',
    label: 'Peer Group Median Implied Rating | YoY Change',
    isDefault: true,
    isDisabled: false,
  },
  {
    id: 'early-warning-signal',
    label: 'Early Warning Signal',
    isDefault: true,
    isDisabled: false,
  },
  {
    id: '5-yr-pit-yoy-aod',
    label: '5-Year PiT PD | YoY Change',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '5-yr-pit-pd-ir-yoy',
    label: '5-Year PiT PD Implied Rating | YoY Change',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '1-yr-ttc-pd-yoy-aod',
    label: '1-Year TTC PD | YoY Change',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '1-yr-ttc-pd-ir-yoy',
    label: '1-Year TTC PD Implied Rating | YoY Change',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '5-yr-ttc-pd-yoy-aod',
    label: '5-Year TTC PD | YoY Change',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '5-yr-ttc-pd-ir-yoy',
    label: '5-Year TTC PD Implied Rating | YoY Change',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: 'moodys-last-rating-change-date',
    label: 'Moody’s Rating',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '1-yr-cds-i-pd',
    label: '1-Year CDS-I PD',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '1-yr-cds-i-pd-ir',
    label: '1-Year CDS-I PD Implied Rating',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '5-yr-cds-i-pd',
    label: '5-Year CDS-I PD',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: 'credit-sentiment-score',
    label: 'Credit Sentiment Score',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '1-yr-expected-loss-implied-rating',
    label: '1-Year Expected Loss Implied Rating',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '5-yr-cds-spread',
    label: '5-Year CDS Spread',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '1-yr-pit-pd-pre-support',
    label: 'Pre-Support 1-Year PiT PD | YoY Change',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '1-yr-pit-pd-implied-rating-pre-support',
    label: 'Pre-Support 1-Year PiT PD Implied Rating | YoY Change',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '1-yr-pit-pd-post-support',
    label: 'Post-Support 1-Year PiT PD | YoY Change',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: '1-yr-pit-pd-implied-rating-post-support',
    label: 'Post-Support 1-Year PiT PD Implied Rating | YoY Change',
    isDefault: false,
    isDisabled: false,
  },
  {
    id: 'trade-credit-limit',
    label: 'Trade Credit Limit',
    isDefault: false,
    isDisabled: false,
  },
];

export const enum ModelName {
  CREDIT_EDGE = 'CreditEdge',
  RISK_CALC = 'RiskCalc',
  PAYMENT = 'Payment',
  ENHANCED_BENCHMARK = 'Enhanced Benchmark',
  BENCHMARK = 'Benchmark',
  OVERLAY = 'Overlay',
  IMPLICIT_SUPPORT = 'Implicit Support',
  EXPLICIT_SUPPORT = 'Explicit Support',
  ADVERSE_PARENT_SUPPORT = 'Adverse Parent Support',
  UNKNOWN = 'Unknown',
}

export const enum Entitlements {
  MIR_MEDIAN_CREDIT_SPREAD = 'MIR Median Credit Spread',
  ORBIS_ALL_COMPANIES = 'ORBIS All Companies',
  PUBLIC_ENTITLEMENT = 'EDF-X Public',
  PUBLIC_PLUS_ENTITLEMENT = 'EDF-X: Public Plus',
  PRIVATE_ENTITLEMENT = 'EDF-X Private',
}
export const MAX_FINANCIAL_STATEMENTS = 10;

// todo: consolidate implied rating constants (https://ma-pa-creditanalytics.atlassian.net/browse/EDFX-13719)
export const IMPLIED_RATING_CLASSIFICATIONS = [
  'Aaa',
  'Aa1',
  'Aa2',
  'Aa3',
  'A1',
  'A2',
  'A3',
  'Baa1',
  'Baa2',
  'Baa3',
  'Ba1',
  'Ba2',
  'Ba3',
  'B1',
  'B2',
  'B3',
  'Caa-C',
  'C',
];
