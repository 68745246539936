import { Component, Input } from '@angular/core';
import { MdbTooltipPosition } from 'mdb-angular-ui-kit/tooltip';
import { MdbTooltipTrigger } from '../edfx-tooltip/edfx-tooltip.directive';

@Component({
  selector: 'edfx-info-icon',
  templateUrl: './edfx-info-icon.component.html',
  styleUrls: ['./edfx-info-icon.component.scss'],
})
export class EdfxInfoIconComponent {
  @Input() tooltip!: string;
  @Input() tooltipTitle!: string;
  @Input() tooltipMaxWidth = '300px';
  @Input() tooltipPlacement: MdbTooltipPosition = 'top';
  @Input() tooltipTrigger: MdbTooltipTrigger = 'hover';
  @Input() html = false;
  @Input() outlined = false;
  @Input() color = '#000000';
  @Input() label = '';
  @Input() iconName = 'info';

  constructor() {}
}
