import * as moment from 'moment';

export class Utils {
  public static isNullOrUndefined(obj: any) {
    return obj === null || obj === undefined || obj === '';
  }

  public static generateUUID(): string {
    let seed: number = new Date().getTime();

    const uuid: string = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
      // eslint-disable-next-line no-bitwise
      const r: number = (seed + Math.random() * 16) % 16 | 0;
      seed = Math.floor(seed / 16);
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  public static getLatestDate(latestKnownPdDate: string): moment.Moment {
    return !this.isNullOrUndefined(latestKnownPdDate) ? moment(latestKnownPdDate) : moment(Date.now());
  }

  public static transformToPercent(value: number | string) {
    if (this.isNullOrUndefined(value)) {
      return null;
    } else if (typeof value !== 'string' && typeof value !== 'number') {
      throw new Error(value + ' is not a string or number');
    }
    return Math.round(+value * 10000) / 100;
  }
}
