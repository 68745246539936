import { Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';
import { MdbTooltipDirective, MdbTooltipPosition } from 'mdb-angular-ui-kit/tooltip';

export type MdbTooltipTrigger = 'hover' | 'click' | 'focus' | 'manual';

/* eslint-disable  */
@Directive({
  selector: '[edfxTooltip]'
}) /* eslint-enable */
export class EdfxTooltipDirective extends MdbTooltipDirective implements AfterContentInit {
  private pEdfxTooltip = '';
  private pEdfxTooltipTitle = '';

  // TODO DON'T REMOVE : try to manage inner style but for security questions, not sure to use this code... If you
  //  need styling the inner content, you could add class to the content. If you use style it will be removed by
  //  the DomSanitizer.
  // private pEdfxTooltipContentStyle = '';

  constructor(pOverlay: Overlay, pOverlayPositionBuilder: OverlayPositionBuilder, pElementRef: ElementRef) {
    super(pOverlay, pOverlayPositionBuilder, pElementRef);
    this.html = true;
  }

  public ngAfterContentInit(): void {
    this.updateTooltip();
  }

  @Input() set edfxTooltip(value: string) {
    this.pEdfxTooltip = value;
    this.updateTooltip();
  }

  @Input() set edfxTooltipTrigger(value: MdbTooltipTrigger) {
    this.trigger = value as string;
  }

  @Input() set edfxTooltipTitle(value: string) {
    this.pEdfxTooltipTitle = value;
    this.updateTooltip();
  }

  @Input() set edfxTooltipPlacement(value: MdbTooltipPosition) {
    this.placement = value;
  }

  @Input() set edfxTooltipDelayShow(value: number) {
    this.delayShow = value;
  }

  // TODO DON'T REMOVE : try to manage inner style but for security questions, not sure to use this code... If you
  //  need styling the inner content, you could add class to the content. If you use style it will be removed by
  //  the DomSanitizer.
  /* @Input() set edfxTooltipContentStyle(value: string) {
    this.pEdfxTooltipContentStyle = this.sanitizer.sanitize(SecurityContext.STYLE, value);
    this.updateTooltip();
  }*/

  @Input() set edfxTooltipMaxWidth(width: string) {
    if (width) {
      document.documentElement.style.setProperty('--edfx-tooltip-max-width', width);
    }
  }

  private updateTooltip() {
    if (this.pEdfxTooltipTitle || this.pEdfxTooltip) {
      this.mdbTooltip = '';
      if (this.pEdfxTooltipTitle) {
        this.mdbTooltip += `<div class="edfx-tooltip-title"><span>script${this.pEdfxTooltipTitle}</span></div>`;
      }
      if (this.pEdfxTooltip) {
        this.mdbTooltip += `<div class="edfx-tooltip-txt">${this.pEdfxTooltip}</div>`;
      }
    } else {
      this.tooltipDisabled = true;
    }

    // TODO DON'T REMOVE : try to manage inner style but for security questions, not sure to use this code... If you
    //  need styling the inner content, you could add class to the content. If you use style it will be removed by
    //  the DomSanitizer.
    /**  const tooltipContent = (this.pEdfxTooltipTitle ? '<div class="edfx-tooltip-title"><span>script' +
     *  this.pEdfxTooltipTitle + '</span></div>' : '') +
    '<div class="edfx-tooltip-txt"  style="' +
    this.pEdfxTooltipContentStyle +
    '"> ' +
    this.pEdfxTooltip +
    '</div>';
    // Bypass basic html sanitise to allow styling. The style is already sanitised in the setter. Verify the
    // script/innerHtml presence.
     if (
      tooltipContent.toLowerCase().includes('script') ||
      tooltipContent.toLowerCase().includes('iframe') ||
      tooltipContent.toLowerCase().includes('html')
    ) {
      return;
    }
    this.mdbTooltip = this.sanitizer.bypassSecurityTrustHtml(tooltipContent) as string;
    this.mdbTooltip = tooltipContent;**/
  }
}
