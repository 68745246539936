import { Component, Input } from '@angular/core';
import { ICompanyCustomData } from '../interfaces/company-data.interface';

@Component({
  selector: 'edfx-company-editable-profile',
  templateUrl: './company-editable-profile.component.html',
  styleUrls: ['./company-editable-profile.component.scss']
})
export class CompanyEditableProfileComponent {
  tooltipText = 'The Country, State or Industry has been manually modified';
  @Input() data: ICompanyCustomData;
  constructor() {}
}
